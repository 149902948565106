































import { defineComponent, reactive, watch } from "@vue/composition-api";
import countriesList from "@/assets/data/countries.json";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        institution: "",
        city: "",
        country: "",
        language: "",
      }),
    },
    languageVersion: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(_, { emit }) {
    const model = reactive({
      institution: "",
      city: "",
      country: "",
    });

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    return { model, countriesList };
  },
});
